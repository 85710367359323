.cell-inner.papim {
    white-space: nowrap !important;
    min-height: 1.25rem !important;
}

.bg-ultralight {
    font-size: 0.9rem
}
.w-5 {
    width: 5%;
}
.w-10 {
    width: 7%;
}

.collapsed .td {
    border: none
}

.fixHeader {
    overflow-y: auto;

}
.fixHeader thead th {
    position: sticky !important;
    top: 0;
}

.table {
    th, td {
        vertical-align: middle !important;
    }
    table-layout: fixed;
}

.vh-70 {
    height: 70vh !important;
}

.mastcontainer {
    font-size: 0.9rem;
}

.badge {
    border-radius: 0.5rem;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.alert-enter {
    opacity: 0;
  }
  .alert-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  .clickable {
    cursor: pointer;
  }

  .form-error {
    position: absolute;
    z-index: 2000;
    right: 0;
}

.badge-get {
    background-color: #2F8132;
    color: white;
}
.badge-post {
    background-color: #186FAF;
    color: white;
}
.badge-put {
    background-color: #95507c;
    color: white;
}
.badge-options {
    background-color: #947014;
    color: white;
}
.badge-patch {
    background-color: #bf581d;
    color: white;
}
.badge-delete {
    background-color: #cc3333;
    color: white;
}
.badge-basic {
    background-color: #707070;
    color: white;
}
.badge-link {
    background-color: #07818F;
    color: white;
}
.badge-head {
    background-color: #A23DAD;
    color: white;
}
